// @flow

import React from "react";

import { graphql } from "gatsby";
import Render from "../components/Contentful";
import { Router } from "@reach/router";
import { PageContent } from "@containers";
import { addLocale, useLocale } from "ttag";

type ToRenderProps = {
    path?: string,
    locale?: string,
    default?: *,
};

type NotFoundProps = {
    data: {
        allC21Translation: {
            nodes: *[],
        },
        allContentfulWebLandingPage: {
            nodes: *[],
        },
        allContentfulNavigation: {
            nodes: *[],
        },
        footerItems: {
            nodes: *[],
        },
    },
};

const browser = typeof window !== "undefined" && window;

const localizeContent = (content: *, locale: *) => {
    const localized = content.filter((el) => el.node_locale === locale);

    return localized && localized.length
        ? localized
        : content.filter((el) => el.node_locale === "nl");
};

const NotFound = ({
    data: {
        allContentfulWebLandingPage: { nodes },
        allContentfulNavigation,
        footerItems,
        allC21Translation,
    },
}: NotFoundProps) => {
    const ToRender = ({ locale }: ToRenderProps) => {
        addLocale(
            locale,
            localizeContent(allC21Translation?.nodes, locale)[0].translations,
        );
        useLocale(locale);
        return (
            <PageContent
                locale={locale === "nl" || locale === "fr" ? locale : "nl"}
                navigationItems={
                    localizeContent(allContentfulNavigation?.nodes, locale)[0]
                        .navigationItems
                }
                translationSlugs={{ nl: "/nl", fr: "/fr" }}
                footerItems={localizeContent(footerItems?.nodes, locale)[0]}
            >
                {Render(localizeContent(nodes, locale)[0].pageContent, locale)}
            </PageContent>
        );
    };
    return (
        browser && (
            <Router>
                <ToRender path="/:locale/:url" />
                <ToRender path="/:locale/" />
                <ToRender default />
            </Router>
        )
    );
};

export default NotFound;

export const query = graphql`
    query {
        allC21Translation {
            nodes {
                node_locale: locale
                translations(
                    keys: ["common.text.language", "agency.header.button.text"]
                )
            }
        }
        allContentfulNavigation(filter: { title: { eq: "Main Navigation" } }) {
            nodes {
                node_locale
                ...ContentfulNavigation
            }
        }
        footerItems: allContentfulNavigation(
            filter: { title: { eq: "Footer" } }
        ) {
            nodes {
                node_locale
                ...ContentfulNavigation
            }
        }
        allContentfulWebLandingPage(
            filter: { internalLabel: { eq: "404 page" } }
        ) {
            nodes {
                node_locale
                pageContent {
                    ... on ContentfulQuickSearchBlock {
                        id
                        node_locale
                        background {
                            size
                            color
                            position
                        }
                        content {
                            contentTitle
                            contentSubtitle
                            squareImage {
                                fluid(maxWidth: 600) {
                                    ...GatsbyContentfulFluid_withWebp
                                }
                                description
                            }
                        }
                        quickLinks {
                            type
                            __typename
                            linkItems {
                                ... on ContentfulLink {
                                    id
                                    linkTitle
                                    icon {
                                        fixed(width: 100, height: 100) {
                                            width
                                            height
                                            src
                                            srcSet
                                            srcWebp
                                            srcSetWebp
                                        }
                                    }
                                    externalLink
                                    contentfulInternalLink {
                                        ... on ContentfulWebLandingPage {
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on ContentfulQuickLinkBlock {
                        id
                        type
                        title
                        hideStepsTitle
                        background {
                            size
                            color
                            position
                        }
                        linkItems {
                            ... on ContentfulLink {
                                id
                                contentfulInternalLink {
                                    ... on ContentfulWebLandingPage {
                                        slug
                                    }
                                }
                                externalLink
                                linkTitle
                                linkSubtitle {
                                    linkSubtitle
                                }
                                icon {
                                    fixed(width: 100, height: 100) {
                                        width
                                        height
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                    }
                                }
                                image {
                                    fixed(width: 217, height: 185) {
                                        width
                                        height
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                    }
                                    description
                                }
                            }
                            ... on ContentfulCollection {
                                internalLabel
                                query
                                content {
                                    contentTitle
                                    squareImage {
                                        fixed(width: 217, height: 185) {
                                            width
                                            height
                                            src
                                            srcSet
                                            srcWebp
                                            srcSetWebp
                                        }
                                        description
                                    }
                                }
                            }
                            ... on ContentfulWebLandingPage {
                                id
                                slug
                                pageContent {
                                    ... on ContentfulBlog {
                                        id
                                        title
                                        heroImage {
                                            fluid(maxWidth: 568, quality: 80) {
                                                ...GatsbyContentfulFluid_withWebp
                                            }
                                            description
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on ContentfulImageInformationBlock {
                        id
                        node_locale
                        type
                        background {
                            size
                            color
                            position
                        }
                        backgroundColor
                        textColor
                        imagePosition
                        maxHeight
                        link {
                            linkTitle
                            externalLink
                            contentfulInternalLink {
                                ... on ContentfulWebLandingPage {
                                    slug
                                }
                                ... on ContentfulCollection {
                                    query
                                }
                            }
                        }
                        content {
                            contentTitle
                            contentSubtitle
                            landscapeImage {
                                fluid(maxWidth: 1172) {
                                    ...GatsbyContentfulFluid_withWebp
                                }
                                description
                            }
                            squareImage {
                                fluid(maxWidth: 600) {
                                    ...GatsbyContentfulFluid_withWebp
                                }
                                description
                            }
                        }
                    }
                    ... on ContentfulBlog {
                        id
                        title
                        author {
                            name
                            link
                            image {
                                fixed(width: 100, height: 100) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                }
                            }
                        }

                        content {
                            ... on ContentfulRichText {
                                childContentfulRichTextTextRichTextNode {
                                    json
                                }
                                internal {
                                    type
                                }
                            }
                        }

                        heroImage {
                            fluid(maxWidth: 1172) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                    }
                    ... on ContentfulRichText {
                        childContentfulRichTextTextRichTextNode {
                            json
                        }
                        internal {
                            type
                        }
                    }
                    ... on ContentfulUspBlock {
                        id
                        type
                        uspItems {
                            linkTitle
                            linkSubtitle {
                                linkSubtitle
                            }
                            icon {
                                fixed(width: 100, height: 100) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                }
                            }
                        }
                        title
                    }
                    ... on ContentfulContactHqBlock {
                        id
                        internalLabel
                        internal {
                            type
                        }
                    }
                }
            }
        }
    }
`;
